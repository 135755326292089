<template>
  <div v-if="auth.userTenantAdminTenants">
    <UDropdown :items="items" mode="click" :popper="{ placement: 'bottom-start' }">
      <UTooltip text="Change organisation">
        <UButton color="gray" variant="ghost" icon="i-heroicons-square-2-stack" />
      </UTooltip>
    </UDropdown>
  </div>
</template>

<script setup lang="ts">

import { useAuthState } from '~/stores/auth'

// Stores
const auth = useAuthState()
const activeTenantId = useActiveTenantId()

const items = [
  [{
    label: 'Switch Tenant'
    // avatar: {
    //   src: 'https://avatars.githubusercontent.com/u/739984?v=4'
    // }
  }]
]

const getItems = () => {
  const result = auth.userTenantAdminTenants.map((tenantId) => {
    return {
      label: tenantId,
      icon: 'i-heroicons-document-duplicate-20-solid',
      disabled: tenantId === activeTenantId,
      click: () => {
        useSwitchTenant(tenantId)
      }
    }
  })
  items.push(result)
}

getItems()

</script>
